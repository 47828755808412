var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"myModal","centered":"","size":"sm"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"surface",class:{ 'loading': _vm.disp_loading }}),_c('Transition',{attrs:{"name":"disp_status"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.disp_success),expression:"disp_success"}],staticClass:"disp_status text-success"},[_c('i',{staticClass:"fa fa-check-circle d-block",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.status_description)+" ")])]),_c('Transition',{attrs:{"name":"disp_status"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.disp_failure),expression:"disp_failure"}],staticClass:"disp_status text-danger"},[_c('i',{staticClass:"fa fa-times-circle d-block",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.status_description)+" ")])]),_c('Transition',{attrs:{"name":"loading"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"my_spin"},[_c('div',{staticClass:"spinner-border"})])]),_c('div',{staticClass:"mheader w-100"},[_c('h4',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$store.state.modal === 1 ? 'Log in' : 'Sign in')+" ")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.modal_close}},[_vm._v(" X ")])],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"mx-auto"},[_c('b-button',{staticClass:"ms-2",attrs:{"variant":"success"},on:{"click":_vm.register}},[_vm._v("Sign in")]),_c('b-button',{staticClass:"ms-2",attrs:{"variant":"primary"},on:{"click":_vm.login}},[_vm._v("Log in")])],1)]},proxy:true}])},[_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"text-end"},[_vm._v(" Account： ")]),_c('div',{staticClass:"text_input"},[_c('div',{staticClass:"input-group w-100"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-user"})]),_c('b-form-input',{class:{
                    'myFocus': _vm.id.length > 0
                },model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1),_c('b-alert',{staticClass:"p-1",attrs:{"variant":"danger","show":_vm.id.length && !_vm.id_legal}},[_c('i',{staticClass:"fa fa-exclamation-circle",attrs:{"aria-hidden":"true"}}),_vm._v(" The account id can only consist of numbers, 26 letters and underscores, and must be no less than 3 characters and no more than 20 characters in length. ")])],1)]),_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"text-end"},[_vm._v(" Password： ")]),_c('div',{staticClass:"text_input"},[_c('div',{staticClass:"input-group w-100"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-key"})]),_c('b-form-input',{class:{
                    'myFocus': _vm.password.length > 0
                },attrs:{"type":_vm.psw_vis ? 'text' : 'password'},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.password.length > 0),expression:"password.length > 0"}],staticClass:"vis input-group-text",class:{ 'green_vis': _vm.psw_vis },on:{"click":function($event){_vm.psw_vis = !_vm.psw_vis}}},[(!_vm.psw_vis)?_c('i',{staticClass:"fa fa-eye-slash",attrs:{"aria-hidden":"true"}}):_c('i',{staticClass:"fa fa-eye",attrs:{"aria-hidden":"true"}})]),_c('b-alert',{staticClass:"p-1",attrs:{"variant":"danger","show":_vm.password.length && !_vm.password_legal}},[_c('i',{staticClass:"fa fa-exclamation-circle",attrs:{"aria-hidden":"true"}}),_vm._v(" The password can only be composed of digits, 26 letters and underscores, and must be no less than 6 characters and no more than 20 characters in length! ")])],1)])]),(_vm.$store.state.modal === 2)?_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"text-end"},[_vm._v(" Confirm： ")]),_c('div',{staticClass:"text_input"},[_c('div',{staticClass:"input-group"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-key"})]),_c('b-form-input',{class:{
                    'myFocus': _vm.repassword.length > 0
                },attrs:{"type":_vm.psw_vis ? 'text' : 'password'},model:{value:(_vm.repassword),callback:function ($$v) {_vm.repassword=$$v},expression:"repassword"}}),_c('b-alert',{staticClass:"p-1",attrs:{"variant":"danger","show":_vm.password.length &&
                    _vm.repassword.length &&
                    (
                        !_vm.password_legal || _vm.password !== _vm.repassword
                    )}},[_c('i',{staticClass:"fa fa-exclamation-circle",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(!_vm.password_legal ? 'Password is invalid！' : 'The two passwords are inconsistent！')+" ")])],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }