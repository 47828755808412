var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart_more_shop"},[_vm._m(0),_c('div',{staticClass:"row mycards"},_vm._l((_vm.rlist),function(item,index){return _c('div',{staticClass:"mycards_options"},[_c('div',{staticClass:"card"},[_c('router-link',{staticClass:"card-img-top w-75 mx-auto mt-3",attrs:{"to":{
                    name: 'product',
                    query: {
                        pid: item.pid
                    }
                }}},[_c('img',{staticClass:"mx-auto d-block w-100",attrs:{"src":'' + item.img_path,"alt":"Product picture"}})]),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(item.pname))]),_c('p',{staticClass:"card-text"},[_vm._v("₹"+_vm._s(item.min_price)+" ")])]),_c('button',{staticClass:"gocheck",on:{"click":function($event){return _vm.$router.push({
                    name: 'product',
                    query: {
                        pid: item.pid
                    }
                })}}},[_vm._v("Add to cart")])],1)])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"rec_title"},[_c('span',[_vm._v("The person who bought the items in the cart also bought them")])])
}]

export { render, staticRenderFns }