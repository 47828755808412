<template>
    <div class="post_area">
        <div class="container-xl">
            <!-- <div class="xiaomi_logo">
                <img src="../assets/images/logo-mi2.png" alt="" class="xiaomi_logo">
            </div> -->

            <div class="post_right">

                <div>
                    <p>© Copyright <em>RAUM SALES AND DISTRIBUTION PRIVATE LIMITED</em>All Rights Reserved
                    </p>
                    <p>（ GST Registration Number : 07AARCM6479R1Z5</p>
                </div>
                <!-- <a href="javascript:;"><img src="https://digibizshop.in/wp-content/themes/woodmart/images/payments.png"
                        style="width: 700rem;"></a> -->
                <!-- <a href="javascript:;"><img src="../assets/images/v-logo-2.png"></a>
                <a href="javascript:;"><img src="../assets/images/icon3.png"></a>
                <a href="javascript:;"><img src="../assets/images/ba10428a4f9495ac310fd0b5e0cf8370.png"></a>
                <a href="javascript:;"><img src="../assets/images/8db929caaf8cab755ae29640a8214dc6.png"></a> -->
            </div>
            <!-- <div class="slogan">
                探索黑科技，小米为发烧而生
            </div> -->
        </div>

    </div>
</template>
<script>
export default {
    name: 'post_area'
}
</script>
<style lang="less">
.post_area {
    width: 100%;
    background-color: var(--gray_bg);
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .xiaomi_logo {
        /* width:10%; */
        display: block;
        height: auto;
        width: 10%;

        /* max-height: 100px; */
        /* background-color: aqua; */
        img {
            /* width:60px; */
            /* height:30px; */

            padding-top: 30px;
            width: 100%;
            max-width: 60px;
            max-height: 60px;
        }

    }

    .post_right {
        font-size: 13px;
        padding: 20px;
        color: var(--topbar_color);
        box-sizing: border-box;
        width: 90%;
        display: inline-block;

        /* background-color: blueviolet; */
        img {
            /* width:60px; */
            /* height:30px; */

            max-width: 60px;
            max-height: 60px;
        }
    }


    .slogan {
        margin: 8px 0 48px 0;
        width: 100%;
        text-align: center;
        color: var(--topbar_color);
    }

}
</style>