<template>
    <div class="panel" :id="gitem_list.id">
        <!-- <slot></slot> -->
        <div class=" p_header">
            <!-- <div class="col-6 left_title"> -->
            <p>{{ gitem_list.left_title }}</p>

            <!-- </div> -->
            <div class="d-inline-block float-end">
                <div class=" right_tabs">
                    <a href="javascript:void(0);" v-for="r_tab, index of gitem_list.right_tabs"
                        :class="{ 'a_selected': cur_disp === index && r_tab.type === 1 }" @mouseover="cur_disp = index">
                        <!-- &nbsp; -->
                        {{ r_tab.content }}
                        <i class="fa fa-angle-right" v-if="r_tab.type === 0"></i>
                    </a>
                </div>

            </div>

        </div>
        <div class="grid_container" v-for="gitem_list_group, index of gitem_list.arr" v-show="cur_disp === index">
            <!-- <vue_gitem v-for="item of phone" :gitem_list_item="item">
            </vue_gitem> -->
            <router-link class="gitem left_banner" href=""
                :class="{ 'half_left_banner': gitem_list.banner.length === 2 }" :to="{
                    name: 'product',
                    query: {
                        pid: gitem_list.banner[0].id,
                        pname: gitem_list.banner[0].name
                    }
                }">
                <img :src="gitem_list.banner[0].img_path" class="left_img">
            </router-link>
            <router-link class="gitem left_banner half_left_banner" href="" :to="{
                name: 'product',
                query: {
                    pid: gitem_list.banner[1].id,
                    pname: gitem_list.banner[1].name
                }
            }" v-if="gitem_list.banner.length === 2" style="grid-row: 2/span 1;">
                <img :src="gitem_list.banner[1].img_path">
            </router-link>
            <template v-for="gitem_list_item, index of gitem_list_group">
                <!-- <router-link class="gitem left_banner" href=""
                    :class="{ 'half_left_banner': gitem_list.banner.length === 2 }" :to="{
                        name: 'product'
                    }" v-if="index===4&& gitem_list.banner.length === 2">
                    <img :src="this.$store.state.location_prefix + gitem_list.banner[1].img_path">
                </router-link> -->
                <router-link class="gitem" :to="{
                    name: 'product',
                    query: {
                        pid: gitem_list_item.id,
                        pname: gitem_list_item.name
                    }
                }" v-if="!gitem_list_item.halfs">
                    <div class="gitem_card">
                        <img :src="gitem_list_item.img_path">
                        <h6 class="oneWord">{{ gitem_list_item.name }}</h6>
                        <p v-html="gitem_list_item.discription" class="gitem_card_context"></p>
                        <div class="price">
                            <span>₹{{ gitem_list_item.price }}</span>&nbsp;<del>{{ gitem_list_item.old_price }}</del>
                        </div>
                    </div>
                </router-link>


                <div class="last_icon" v-else>
                    <router-link class="last_icon_half" v-for="h of gitem_list_item.halfs" :to="{
                        name: 'product',
                        query: {
                            pid: h.id,
                            pname: h.name
                        }
                    }">
                        <img :src="h.img_path" v-if="h.img_path" style="margin: 16px;width: 90px;">

                        <p>{{ h.name }}</p>
                        <p v-if="h.price" class="price">${{ h.price }}</p>
                        <p v-if="h.more_of_what" class="more">
                            Read More<br>
                            <small>{{ h.more_of_what }}
                                <i class="fa fa-arrow-circle-o-right"></i>
                            </small>
                        </p>

                    </router-link>
                    <!-- <div class="last_icon_half" v-if="gitem_list_item.halfs[1]">
                        <p v-html="gitem_list_item.halfs[1].p1"></p>
                        <p v-if="gitem_list_item.halfs[1].p2" v-html="gitem_list_item.halfs[1].p2"></p>
                        <i v-if=    "gitem_list_item.halfs[1].i_class" class="fa" :class="gitem_list_item.halfs[1].i_class">
                        </i>
                    </div> -->
                </div>
                <!-- <a class="gitem left_banner half_left_banner" href="" v-else-if="gitem_list_item.type === 3">
                    <img :src="gitem_list_item.path">
                </a> -->
            </template>
        </div>
    </div>
</template>
<script>
export default {
    props: ['gitem_list'],
    data() {
        return {
            cur_disp: 0
        }
    },
    computed: {
        len() {
            return this.gitem_list.arr.length
        }
    }
}
</script>
<style lang="less" scoped>
.oneWord {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@title_black: #333;
@desc_gray: #b0b0b0;
@media (max-width:700px) {
    .panel {
        div.p_header {
            p {
                font-size: 16px;
                line-height: 1em;
            }

            div.right_tabs {
                // justify-content: left;

                a {
                    font-size: 10px;
                    margin-left: 5px;
                    line-height: 16px;
                }
            }
        }
    }


}

.panel {
    margin-top: 30px;

    .p_header {

        // display: flex;
        p {
            display: inline-block;
            font-size: 22px;
            font-weight: 200;
            line-height: 2rem;
        }

        .right_tabs {
            display: flex;
            justify-content: right;

            // float: right;
            a {
                // @media (max-width:700px) {
                //     font-size: 10px;
                //     margin-left: 10px;

                // }

                display: inline-block;
                // float: right;
                font-size: 16px;
                font-weight: 400;
                // line-height: 2rem;
                // padding: 0 10px;
                margin-left: 20px;
                text-decoration: none;
                color: black;
                transition: all .5s linear;

                &.a_selected {
                    color: orange !important;
                    border-bottom: 1px solid orange;
                    // text-decoration: underline;
                }

                >.fa-angle-right {
                    display: inline-block;
                    text-align: center;
                    color: white;
                    /* padding: 10px; */
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: var(--topbar_color);
                    font-size: 16px !important;
                    // line-height: 2rem;
                }
            }
        }

    }

    @frNum: 5;
    @frRow: 2fr;

    @media (min-width:700px) {
        .grid_container {
            display: grid;
            justify-content: space-between;
            grid-template-columns: repeat(5, 2fr);
            gap: 16px;
        }

        .gitem_card_context,
        .oneWord {
            display: block;
        }
    }

    @media (max-width:700px) {
        .grid_container {
            display: grid;
            justify-content: space-between;
            grid-template-columns: repeat(2, 5fr);
            gap: 8px;
        }

        .gitem_card_context,
        .oneWord {
            display: none;
        }
    }

    >.grid_container {
        width: 100%;
        margin-top: 10px;
        /* background-color: #2196F3; */
        // padding: 10px;

        // p,
        // h6,
        // span,
        // del,
        // i {
        //     @media (max-width:700px) {

        //         font-size: 0.4rem !important;
        //     }
        // }

        .last_icon {
            // height: 300px;
            text-align: center;
            font-size: 14px;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            // @media (max-width:700px) {
            //     height: 200px !important;
            //     font-size: 8px;
            // }

            .last_icon_half {
                text-decoration: none;
                text-align: left;
                position: relative;
                width: 100%;
                height: 45%;
                background-color: white;
                transition: all .5s linear;
                color: @title_black;

                &:hover {
                    box-shadow: var(--shadow1);
                    transform: translateY(-1%);
                }

                img {
                    width: 45%;
                    display: inline-block;
                    float: right;
                }

                p {
                    margin-top: 5px;
                    padding: 10px;

                    @media (max-width:700px) {
                        padding: 0;
                        margin: 10px auto;

                    }
                }

                .price {
                    color: orange;
                }

                .fa-arrow-circle-o-right {
                    font-size: 60px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10%;
                    color: orange;

                    @media (max-width:700px) {
                        font-size: 15px;
                    }
                }

                .more {
                    font-size: 18px;

                    @media (max-width:700px) {
                        font-size: 8px;
                    }

                    small {
                        font-size: 12px;
                        color: #757575;
                    }
                }
            }
        }

        .gitem {
            display: block;
            height: 300px;
            text-align: center;
            /* color:white; */
            /* width: 20%; */
            background-color: rgba(255, 255, 255, 0.8);
            text-align: center;
            /* padding: 60px; */
            /* max-width: 150px; */
            font-size: 30px;
            text-decoration: none;
            transition: all .5s linear;

            @media (max-width:700px) {
                height: 200px;
            }

            &:hover {
                box-shadow: var(--shadow1);
                transform: translateY(-1%);

            }

            >.gitem_card {
                // width: 100%;
                // height: 100%;
                display: flex;
                color: black;
                position: relative;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;



                .gitem_card_context {
                    text-align: center;
                    width: 150px;
                    /* 限定宽度 */
                    white-space: nowrap;
                    /* 强制文本不换行 */
                    overflow: hidden;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis;
                    /* 超出部分以省略号显示 */

                }

                >h6 {
                    height: 45px;
                    font-size: 15px;

                    @media (max-width:700px) {
                        height: 30px;
                        font-size: 8px;
                    }
                }

                >img {
                    width: 120px;
                    height: 135px;
                    display: block;

                    padding: 16px auto;
                    margin: 16px 0 24px 0;
                }

                >p {
                    font-size: 12px;
                    color: @desc_gray;

                    @media (max-width:700px) {
                        font-size: 6px;

                    }
                }

                >.price {
                    box-sizing: border-box;
                    width: 100%;
                    text-align: center;
                    padding-bottom: 20px;
                    font-size: 15px;
                    /* left:50%; */
                    /* transform: translateX(-50%); */
                    color: orange;

                    >del {
                        color: var(--topbar_color);
                    }

                    @media (max-width:700px) {
                        font-size: 6px;
                    }
                }

            }

        }

        .left_banner {
            height: 610px;
            grid-row: 1 / span 2;

            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media (max-width:700px) {
                height: 410px;
            }
        }

        .half_left_banner {
            grid-row: span 1;
            height: 300px;

            @media (max-width:700px) {
                height: 200px;

            }
        }



    }
}
</style>