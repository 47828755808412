import { render, staticRenderFns } from "./topbar_cart.vue?vue&type=template&id=dd5094d4"
import script from "./topbar_cart.vue?vue&type=script&lang=js"
export * from "./topbar_cart.vue?vue&type=script&lang=js"
import style0 from "./topbar_cart.vue?vue&type=style&index=0&id=dd5094d4&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports